.root {
  /* display: flex;
  flex-direction: column; */
  /* padding: 1rem 1rem 2rem; */
  /* background-color: #fff; */
  /* min-height: 10vh; */
  /* width: 700px; */
}

.header {
  text-align: center;
  background-color: #fcfeff;
}


.table {
  margin-top: 2rem;
}

.tableRow:nth-of-type(even) {
  background-color: #f2f2f2;
}

/* product table Quantity  */
.product__denomination_table_quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.product__denomination_quantity_icon {
  width: 40px !important;
  height: 28px !important;
  padding: 2px;
  border-radius: 4px;
  margin: 0 12px;
  border: none;
  color: #002865;
  cursor: pointer;
}

.product__denomination_quantity_icon:hover {
  background-color: #c0d6f8;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Resposive media Query */
@media screen and (max-width: 480px) {

  /* Your CSS rules for screens with width up to 480px */
  .root {
    width: 100% !important;
  }
}