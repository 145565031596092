.menu__itemcontainer {
  margin: 0;
  min-height: 250px;
  max-height: 330px;
  min-width: 60%;
  background-color: var(--white-main);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 140px;
  left: 192px;
  border: 0;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
}

.menu__itemcontainer::-webkit-scrollbar {
  background-color: var(--borderPrimary-main);
  width: 10px;
}

.menu__itemcontainer::-webkit-scrollbar-thumb {
  background-color: var(--secondary-main);
  color: blanchedalmond;
  border-radius: 3px;
  height: 40px;
}

.menu__brands {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 10px;
  padding-top: 10px;
}

.menu__listitem {
  text-align: left;
  vertical-align: top;
  font-size: 12px;
  line-height: 1;
  padding-top: 9px;
  padding-bottom: 9px;
  white-space: nowrap;
  cursor: pointer;
}

.menu__list {
  margin: 0;
  padding: 15px 10px !important;
  height: 100%;
  text-align: center;
  text-transform: capitalize !important;
  min-width: 50px !important;
}

/* navbar hovering  */
/* .menu__brands .menu__listitem:hover {
    background-color: var(--borderPrimary-main);
    border-bottom: 1px solid var(--secondary-main);
    font-weight: 500;
}

.menu__list:hover {
    border-bottom: 3px solid;
    border-color: var(--secondary-main) !important;
} */

.menu__list:hover~.menu__isShow {
  display: block !important;
}

.menu__isShow:hover {
  display: block !important;
}

.menu__isShow {
  /* display: none; */
}


.navbarMenuSliderIcons .slick-prev:before,
.navbarMenuSliderIcons .slick-next:before {
  color: var(--white-main) !important;
}


@media screen and (max-width: 480px) {
  .menu__itemcontainer {
    margin: 0;
    min-height: 250px;
    max-height: 330px;
    min-width: 80%;
    background-color: var(--white-main);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    top: 138px;
    left: 0;
    border: 0;
    z-index: 2;
    border-radius: 0;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.05);
    overflow-y: scroll;
  }

  .menu__list {
    padding: 10px 5px !important;
    height: 100%;
    text-transform: capitalize !important;
    min-width: 50px !important;
    font-size: 12px !important;
  }
}