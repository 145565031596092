@media screen and (min-width: 1200px) {

  /* Styles to apply when the screen width is 1200px pixels or greater */
  .navbar__category_container {
    min-width: 25%;
  }

  /* .navbar__search_container {
    width: 75%;
    position: relative;
  } */
}

@media screen and (min-width: 1337px) {

  /* Styles to apply when the screen width is 1337 pixels or greater */
  .navbar__category_container {
    min-width: 20%;
  }
}

@media screen and (max-width: 600px) {
  /* Styles to apply when the screen width is 600px pixels or less */

  .navbar__category_container {
    min-width: 100% !important;
    margin-bottom: 5px;
  }



  .navbar__search_button {
    position: relative !important;
    top: 30px !important;
    width: 100% !important;
  }

}


