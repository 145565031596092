.digitInput input {
    width: 100% !important;
    height: 55px;
    background-color: #ffffffc5;
    border: none;
    line-height: 50px;
    text-align: center;
    font-size: 15.5px;
    color: #10a0cc;
    margin: 0 2px;
    border-radius: 8px;
    font-weight: 500;
    outline-color: #00B8EE;
    border-style: none;
}

.splitter {
    padding: 0 5px;
    color: #00B8EE;
    font-size: 24px;
}

.error {
  border: 1px solid red !important;
}

.success {
  border: 1px solid green !important;
}