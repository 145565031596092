@import url("./fonts/stylesheet.css");

:root {
  --primary-main: #002865;
  --primaryDark-main: #002255;
  --secondary-main: #00B8EE;
  --white-main: #ffffff;
  --dark-main: #000000;
  --box-shadow-main: #808080;
  --footer-background-main: #184282;
  --textPrimary-main: #454545;
  --textPrimary-main1: #505050;
  --borderPrimary-main: #e9ecef;
  --profile-sidebar-bg: #062047;

  --mtitle-1: 22px;
  --title1: 28px;
  --font-family-main: Celias;
}

html {
  font-size: 12px;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: var(--font-family-main), serif;
}

.slick-prev:before,
.slick-next:before {
  color: var(--primary-main) !important;
}

.mobile__UserSideBarBanner {
  /* background-image: url("./assets/banner-images/mobile_header_wallpaper.webp"); */
  background-color: #aeb1b5;
  padding: 10px 5px;
  min-width: 100%;
  max-height: 130px;
  overflow: auto;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: none !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  body::-webkit-scrollbar {
    scrollbarWidth: thin;
  }
}

@media (min-width: 600px) {
  .MuiDrawer-root>.MuiPaper-root {
    min-height: 100% !important;
    overflow: auto;
  }
}


/* Smooth scrolling behavior */
html {
  scroll-behavior: smooth;
}

/* Firefox */
* {
  scrollbarWidth: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* WebKit browsers */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}